import { ShowNotificationFunction } from '@harmon.ie-ce/storybook/src/contexts/NotificationsContext';
import { useGraphClient } from '@services';
import {
  Typography,
  CheckMarkSmall,
  emaiHeaderMappingAnimationDataTeamsLight,
  CustomList,
  IndicatorRequestHandler,
  emaiHeaderMappingAnimationDataSharePointLight,
  InformativeDialog,
  useTheme,
  emaiHeaderMappingAnimationDataSharePointDark,
  emaiHeaderMappingAnimationDataTeamsDark,
  ErrorSmall,
} from '@storybook';
import { strings } from '@vendor/languages';
import React, { useCallback, useRef, useState } from 'react';
import { Step } from '~/context/UploadContext';
import { ehm_black_list, EmailHeaderMapping } from '~/modules/Settings/SettingContext';
import { EmailHeaderMappingChoices } from '~/utilities';
import { trackEvent } from '~/utilities/analytics/tracking';
import { EmailUploadProvider } from '~/utilities/uploadItem/EmailUploadProvider';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';

export const useCreateEmailHeaderMappingStep = (): Step => {
  const dialogPromiseRef = useRef<{ resolve: (value: boolean) => void; reject: (reason?: any) => void } | null>(null);
  const [isActive, setActive] = useState(false);
  const isTeams = useRef(false);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const apiId = useRef('');
  const gcl = useGraphClient();

  const BodySection = (
    <>
      <Typography variant="H1SemiBold">
        {isTeams.current ? strings.lang.ehm_dialog.ehmTeam_title : strings.lang.ehm_dialog.ehmSite_title}
      </Typography>
      <CustomList
        items={[
          isTeams.current ? strings.lang.ehm_dialog.ehmTeam_checkmark1 : strings.lang.ehm_dialog.ehmSite_checkmark1,
          strings.lang.ehm_dialog.ehm_checkmark2,
          strings.lang.ehm_dialog.ehm_checkmark3,
        ]}
        Bullet={<CheckMarkSmall />}
      />
    </>
  );

  const onApprove = useCallback((_checkboxState: boolean) => {
    dialogPromiseRef.current?.resolve(true);
  }, []);

  const onReject = useCallback((checkboxState: boolean) => {
    if (checkboxState) ehm_black_list.value = [...ehm_black_list.value, apiId.current];
    dialogPromiseRef.current?.resolve(false);
  }, []);

  const execute = async (
    uploadItemProvider: UploadItemProvider,
    _index: number,
    _actionsRef: any,
    _isMultipleUploads: boolean,
    _progressHandler?: IndicatorRequestHandler,
    showNotification?: ShowNotificationFunction
  ) => {
    try {
      apiId.current = uploadItemProvider.itemContainer.apiId;
      isTeams.current = uploadItemProvider.itemContainer.OfficeAppLocated === 'Teams';
      const isAllowedBySettings =
        EmailHeaderMapping.value === EmailHeaderMappingChoices.TeamsAbsSharePoint ||
        (isTeams.current && EmailHeaderMapping.value === EmailHeaderMappingChoices.TeamsOnly);
      if (
        isAllowedBySettings &&
        uploadItemProvider instanceof EmailUploadProvider &&
        !ehm_black_list.value.includes(apiId.current)
      ) {
        const emailProvider = uploadItemProvider as EmailUploadProvider;
        const contentTypes = await emailProvider.itemContainer.getContentTypes(gcl);
        const isEmailContentTypeExist = contentTypes.value.find(
          (contentType: any) => contentType.Name === 'Email' || contentType.Name === 'E-mail'
        );
        if (!isEmailContentTypeExist) {
          setActive(true);
          const userResponse = await new Promise<boolean>((resolve, reject) => {
            dialogPromiseRef.current = { resolve, reject };
          });
          trackEvent('Auto Email Header Mapping', {
            Trial: false,
            'User Choice': userResponse ? 'Create view' : 'Not now',
            'Location Type': isTeams.current ? 'Team' : 'Site',
            'User Settings': EmailHeaderMapping.value,
          });
          if (userResponse) {
            setLoading(true);
            await uploadItemProvider.itemContainer.addOrCreateEmailContentType(gcl);
          }
          setActive(false);
          setLoading(false);
          userResponse &&
            showNotification &&
            showNotification(
              strings.lang.ehm_dialog.ehm_successTitle,
              undefined,
              undefined,
              'indicator-still-uploading'
            );
          return;
        } else return;
      }
    } catch (e: any) {
      setActive(false);
      setLoading(false);
      if (e.statusCode !== 403) {
        showNotification &&
          showNotification(
            strings.lang.ehm_dialog.ehm_errorTitle,
            undefined,
            undefined,
            'indicator-still-uploading',
            <ErrorSmall />
          );
      }
    }
  };
  const clean = () => {};
  const getHeaderAnimation = () => {
    if (theme.palette.mode === 'dark') {
      return isTeams.current ? emaiHeaderMappingAnimationDataTeamsDark : emaiHeaderMappingAnimationDataSharePointDark;
    } else {
      return isTeams.current ? emaiHeaderMappingAnimationDataTeamsLight : emaiHeaderMappingAnimationDataSharePointLight;
    }
  };
  const slideUi = isActive ? (
    <InformativeDialog
      BodySection={BodySection}
      approveLabel={strings.lang.ehm_dialog.ehm_primary_button}
      rejectLabel={strings.lang.ehm_dialog.ehm_secondary_button}
      headerAnimation={getHeaderAnimation()}
      onApprove={checkboxState => onApprove(checkboxState)}
      onCancel={checkboxState => onReject(checkboxState)}
      show={isActive}
      showCheckbox
      chackboxLabel={strings.lang.ehm_dialog.ehm_checkboxText}
      isLoading={loading}
    />
  ) : (
    <></>
  );
  return { clean: clean, execute: execute, stepUi: slideUi };
};
