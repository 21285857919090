import { NotificationContext } from '@storybook';
import React, { useContext } from 'react';
import { strings } from '@vendor/languages';
import { useSafeCallbackType } from '~/hooks/UseSafeCallback';
import { UploadItemProvider } from '~/utilities/uploadItem/UploadItemProvider';
import { useCreateEmailHeaderMappingStep } from '~/upload/useCreateEmailHeaderMappingStep';
import { publishNewItem, useUploadStep } from '~/upload/useUploadStep';
import { useCheckIfExistStep } from '~/upload/useCheckIfExistStep';
import { useEmailHeaderMapping } from '~/upload/useEmailHeaderMapping';
import UploadContext from './UploadContext';
import { useGraphClient } from '../../../services/src/networkAndCache';
import IndicatorContext from './IndicatorContext';

export const UploadProvider = (props: any) => {
  const gcl = useGraphClient();
  const { showNotification } = useContext(NotificationContext);
  const uploadSteps = [
    useCreateEmailHeaderMappingStep(),
    useUploadStep(),
    useCheckIfExistStep(),
    //useAddCategory(), // Remove category for now
    useEmailHeaderMapping(),
    publishNewItem(),
  ];
  const {
    errorfulProcessIndication,
    oneItemCompletedIndication,
    oneItemStartIndication,
    progressRequestHandler,
    startProgressIndication,
    successfulProcessIndication,
  } = useContext(IndicatorContext);

  const startUpload: Parameters<useSafeCallbackType> = [
    async (
      items: UploadItemProvider[], // Adjusted type to handle both Files and Emails
      actionRef: React.MutableRefObject<any>
    ) => {
      const inProgress = progressRequestHandler?.inProgress();
      if (Boolean(inProgress)) {
        showNotification(strings.lang.indicatorTexts.unableToRun, undefined, undefined, 'indicator-still-uploading');
        return;
      }
      startProgressIndication?.(
        items.length,
        items.map(ui => ui.getItemName())
      );
      for (let i = 0; i < items.length; i++) {
        if (progressRequestHandler?.needToCancel()) {
          return;
        }
        if (progressRequestHandler?.getCurrentProgressIndex())
          progressRequestHandler.getCurrentProgressIndex().current = i; // Remove optional chaining operator
        oneItemStartIndication?.(i);
        const uploadItem = items[i];
        for (let j = 0; j < uploadSteps.length; j++) {
          await uploadSteps[j].execute(
            uploadItem,
            i,
            actionRef,
            items.length > 1,
            progressRequestHandler,
            showNotification
          );
        }
        oneItemCompletedIndication?.();
      }
      uploadSteps.map(step => step.clean());
      successfulProcessIndication?.();
    },
    [
      gcl,
      oneItemCompletedIndication,
      oneItemStartIndication,
      progressRequestHandler,
      showNotification,
      startProgressIndication,
      successfulProcessIndication,
    ],
    false,
    {},
    undefined,
    errorfulProcessIndication,
  ];

  return (
    <UploadContext.Provider
      value={{
        startUpload: startUpload,
      }}
    >
      {uploadSteps.map((step, index) => (
        <div key={index}>{step.stepUi}</div>
      ))}{' '}
      {props.children}
    </UploadContext.Provider>
  );
};
