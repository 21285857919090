import { IndicatorRequestHandler } from '@storybook';
import { GraphClient } from '../../../../services/src/networkAndCache';
import { UploadItemProps, uploadDocumentToSharePoint } from '../actions';
import { DocumentItem, ItemContainer } from '../itemTypes';

export abstract class UploadItemProvider {
  protected gcl: GraphClient;
  name: string = '';
  item: File | Office.SelectedItemDetails;
  itemContainer: ItemContainer;
  isDragAndDrop: boolean = false;
  newItem: DocumentItem | undefined;
  uploadItemProps: UploadItemProps | undefined;
  isOverride?: boolean = undefined;
  isExist?: boolean = undefined;

  constructor(gcl: any, item: any, itemContainer: ItemContainer, isDragAndDrop: boolean = false) {
    this.gcl = gcl;
    this.item = item;
    this.itemContainer = itemContainer;
    this.isDragAndDrop = isDragAndDrop;
  }

  // Method to get the item name, to be overridden by subclasses
  abstract getItemName(): string;

  getNewItem(): DocumentItem | undefined {
    return this.newItem;
  }
  setNewItem(newItem: DocumentItem): DocumentItem | undefined {
    return (this.newItem = newItem);
  }

  // Method to get the item blob/data, to be overridden by subclasses
  abstract getItemBlob(): Promise<void>;

  // Method to handle the upload process, can be shared or customized further in subclasses
  async uploadItem(
    progressRequestHandler: IndicatorRequestHandler | undefined,
    override?: boolean
  ): Promise<DocumentItem | undefined> {
    await this.getItemBlob();
    if (!this.uploadItemProps) return;
    this.newItem = await uploadDocumentToSharePoint(
      this.gcl,
      this.itemContainer,
      this.uploadItemProps.name,
      progressRequestHandler?.getCurrentProgressIndex().current || 0,
      this.uploadItemProps.data,
      override,
      progressRequestHandler
    );
  }
}
